import React from "react";
import Field from "./field.js";

const NumberField = (props) => {
  const validationFunction = (value) => {
    if (isNaN(parseInt(value))) {
      return false;
    }
    if (props.min && value < props.min) {
      return false;
    }
    if (props.max && value > props.max) {
      return false;
    }
    return true;
  };
  return (<Field {...props} validationFunction={ validationFunction } />);
};

export default NumberField;
